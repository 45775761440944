import { MAILERLITE_API_HOST } from "./constants";
import { getHeaders } from "./helpers";

export function mailerliteSubscribe(email) {
  return fetch(`${MAILERLITE_API_HOST}/subscribers`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ email }),
  }).then((j) => j.json());
}
