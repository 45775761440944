import Button from "../../../components/shared/button";
import Section from "../../../components/shared/section";
import TeamSlider from "./slider";
import TeamMember from "./teamMember";
import { TitleSpan } from "../../../components/shared/title";
import { teamMembers } from "./constants";
import { Trans } from "react-i18next/TransWithoutContext";
import { useTranslation } from "react-i18next";
import SectionHero from "../../../components/shared/sectionHero";
import FadeInUp from "../../shared/animations/fadeInUp";
import FadeIn from "../../shared/animations/fadeIn";

export default function OurTeam() {
  const { t, i18n, ready } = useTranslation("team-page");

  if (!ready) return null;

  return (
    <Section className="mb-[100px]">
      <FadeIn>
        <SectionHero
          title={
            <Trans
              i18nKey="h1"
              ns="team-page"
              i18n={i18n}
              components={[<TitleSpan key="0">equipo</TitleSpan>]}
            />
          }
          subtitle={t("subtitle")}
          content={t("content")}
          cta={<Button href="#offers">{t("seeOffers")}</Button>}
        />
      </FadeIn>

      <div className="mb-[100px] mt-[80px]">
        <FadeIn>
          <TeamSlider />
        </FadeIn>
      </div>
      <div className="grid grid-cols-2 gap-[16px] mh:gap-[24px] tv:grid-cols-3 desktop:grid-cols-4">
        {teamMembers.map((member, i) => (
          <FadeInUp key={`${member.name}-${i}`}>
            <TeamMember {...member} />
          </FadeInUp>
        ))}
      </div>
    </Section>
  );
}
