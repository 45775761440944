import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";
import Button from "../../shared/button";
import Section from "../../shared/section";
import FeaturesGrid from "./featuresGrid";
import TokenSectionHero from "../tokenSectionHero";
import { TitleSpan } from "../../shared/title";
import MoreTokenInfoModal from "./moreInfoModal";
import tokenVid from "../../../static/videos/token_up.webm";
import tokenVidSafari from "../../../static/videos/token_up.mov";
import tokenRotationVid from "../../../static/videos/token_rotation.webm";
import tokenRotationVidSafari from "../../../static/videos/token_rotation.mov";
import tokenVidLoop from "../../../static/videos/token_loop.webm";
import tokenVidLoopSafari from "../../../static/videos/token_loop.mov";
import FadeIn from "../../shared/animations/fadeIn";

const features = [
  {
    emoji: "👑",
    title: "hero.features.0.title",
    content: "hero.features.0.content",
  },
  {
    emoji: "🏆",
    title: "hero.features.1.title",
    content: "hero.features.1.content",
  },
  {
    emoji: "🌍",
    title: "hero.features.2.title",
    content: "hero.features.2.content",
  },
  {
    emoji: "💎",
    title: "hero.features.3.title",
    content: "hero.features.3.content",
  },
];

export default function TokenHero() {
  const rotationRef = useRef(null);
  const loopRef = useRef(null);
  const [activateLoop, setActivateLoop] = useState(false);
  const [activateRotation, setActivateRotation] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const { t, ready, i18n } = useTranslation("token-page");

  if (!ready) return null;

  const featuresI18n = features.map((ft) => ({
    ...ft,
    title: t(ft.title),
    content: t(ft.content),
  }));

  const toggleModal = () => setActiveModal((prev) => !prev);

  const handleInitialVideoEnded = () => {
    setActivateLoop(true);
    setActivateRotation(false);
    if (loopRef.current) {
      loopRef.current.currentTime = 0;
    }
  };

  const handleLoopClicked = () => {
    setActivateRotation(true);
    setActivateLoop(false);
    if (rotationRef.current) {
      rotationRef.current.currentTime = 0;
      rotationRef.current.play();
    }
  };

  return (
    <>
      <div
        className={classNames(
          "w-[410px] h-[410px] mx-auto top-0 left-1/2 -translate-x-1/2 translate-y-[45px] inline-block absolute z-10",
          "tv:w-[486px] tv:h-[486px] th:w-[639px] th:h-[639px] desktop:w-[800px] desktop:h-[800px]",
          "mh:translate-y-[56px] tv:translate-y-[17px] th:translate-y-[0px] desktop:translate-y-[-40px]"
        )}
      >
        <div className="relative">
          <video
            className={classNames(
              "absolute left-0 top-0",
              (activateLoop || activateRotation) && "hidden"
            )}
            onEnded={handleInitialVideoEnded}
            width="100%"
            autoPlay
            muted
            playsInline
          >
            <source src={tokenVidSafari} type="video/quicktime" />
            <source src={tokenVid} type="video/webm" />
          </video>

          <video
            ref={rotationRef}
            className={classNames(
              "absolute z-20 left-0 top-0",
              !activateRotation && "hidden"
            )}
            onEnded={handleInitialVideoEnded}
            width="100%"
            autoPlay
            muted
            playsInline
          >
            <source src={tokenRotationVid} type="video/quicktime" />
            <source src={tokenRotationVidSafari} type="video/webm" />
          </video>

          <video
            ref={loopRef}
            className={classNames(
              "absolute z-10 left-0 top-0",
              !activateLoop && "hidden"
            )}
            width="100%"
            autoPlay
            muted
            playsInline
            loop
          >
            <source src={tokenVidLoopSafari} type="video/quicktime" />
            <source src={tokenVidLoop} type="video/webm" />
          </video>
        </div>
      </div>
      <Section className="text-black mb-[116px] relative z-20">
        <div
          className={classNames(
            "rounded-[50%] absolute top-0 left-1/2 cursor-pointer -translate-x-1/2 w-[410px] h-[410px] tv:w-[486px] tv:h-[486px] th:w-[639px] th:h-[639px] desktop:w-[800px] desktop:h-[800px]",
            "mh:-translate-y-[0px] tv:-translate-y-[17px] th:translate-y-[0px] desktop:translate-y-[-40px]"
          )}
          onClick={handleLoopClicked}
        />
        <FadeIn>
          <TokenSectionHero
            className="!mt-[245px] mh:text-center mh:max-tv:!mt-[180px] th:!mt-[266px] desktop:!mt-[286px]"
            contentContainerCx="mh:max-w-[480px] th:max-w-[680px]"
            titleCx={classNames(
              "[text-shadow:_0px_0px_40px_rgb(255_255_255)]",
              "th:mb-[32px]",
              "mh:max-th:max-w-[525px] mh:max-th:mx-auto mh:text-satoshi-display-md"
            )}
            title={
              <Trans
                i18n={i18n}
                i18nKey="hero.title"
                ns="token-page"
                components={[
                  <TitleSpan className="mh:text-kaisei-display-md">
                    crear valor
                  </TitleSpan>,
                ]}
              />
            }
            subtitle={t("hero.subtitle")}
            subtitleCx="tv:text-satoshi-heading-md th:mb-[24px] th:text-satoshi-heading-lg"
            content={t("hero.description")}
            contentCx="th:mb-[32px] tv:text-satoshi-body-md th:text-satoshi-body-lg"
            cta={
              <Button
                variant="secondary"
                className="shadow-subtle"
                onClick={toggleModal}
              >
                {t("hero.cta")}
              </Button>
            }
          />
        </FadeIn>
        <FadeIn>
          <FeaturesGrid features={featuresI18n} />
        </FadeIn>
      </Section>
      {activeModal && <MoreTokenInfoModal onClose={toggleModal} />}
    </>
  );
}
