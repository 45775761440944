import { Trans, useTranslation } from "react-i18next";
import Section from "../../shared/section";
import TokenSectionHero from "../tokenSectionHero";
import DistributionChart from "./chart";
import classNames from "classnames";
import FadeInUp from "../../shared/animations/fadeInUp";
import dstributionBg from "../../../static/svg/distribution.svg";

export default function TokenDistribution() {
  const { t, ready, i18n } = useTranslation("token-page");

  if (!ready) return null;

  return (
    <Section background="bg-surface-0-primary" className="text-white relative overflow-hidden">
      <div
        className="hidden th:inline-block w-[961px] h-[547px] bg-contain bg-no-repeat bg-center absolute right-[-273px] top-[32px]"
        style={{ backgroundImage: `url(${dstributionBg})` }}
      />
      <div
        className={classNames(
          "mt-[32px] mb-[32px] mh:mt-[38px] tv:mt-[64px] th:mt-[84px]",
          "mh:my-[64px] tv:mt-[80px] tv:mb-[48px] th:mt-[100px] th:mb-[80px] desktop:my-[100px]"
        )}
      >
        <FadeInUp>
          <TokenSectionHero
            className="mb-[40px] th:mb-[56px] desktop:mb-[64px]"
            title={
              <Trans i18n={i18n} i18nKey="distribution.title" ns="token-page" />
            }
            contentContainerCx="mx-0 mh:mx-0 max-w-[640px]"
            subtitle={t("distribution.subtitle")}
            subtitleCx="mh:text-satoshi-heading-md th:text-satoshi-heading-lg"
            content={t("distribution.content")}
            contentCx="text-satoshi-body-sm mh:text-satoshi-body-md th:text-satoshi-body-lg"
          />
        </FadeInUp>
        <FadeInUp>
          <DistributionChart />
        </FadeInUp>
      </div>
    </Section>
  );
}
