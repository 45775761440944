import classNames from "classnames";
import Emoji from "../../shared/emoji";

const colors = ["bg-yellow-0", "bg-red-0", "bg-green-0", "bg-blue-0"];

export default function FeaturesGrid({ features = [] }) {
  return (
    <div
      className={classNames(
        "grid grid-cols-1 gap-[48px] text-black",
        "mh:grid-cols-2 mh:grid-rows-2 mh:gap-x-[32px] tv:gap-y-[56px] th:gap-y-[64px] th:gap-x-[40px] desktop:gap-x-[120px]"
      )}
    >
      {features.map((feature, i) => {
        const color = colors[i % 4];
        return (
          <div
            key={i}
            className="relative z-19 flex flex-col justify-start gap-[24px] desktop:gap-[32px] desktop:flex-row"
          >
            <div>
              <div className="text-[32px] inline-block relative z-10">
                <div
                  className={classNames(
                    "absolute z-10 w-[60px] h-[60px] bottom-0 left-0 blur-[12px] rounded-[60px]",
                    color
                  )}
                />
                <div className="absolute z-0 bg-white opacity-50 w-[68px] h-[77px] bottom-1/2 translate-y-1/2 left-0 blur-[14px] rounded-[77px]" />
                <Emoji className="z-20 relative" symbol={feature.emoji} />
              </div>
            </div>
            <div className="desktop:self-start z-20">
              <h3 className="font-satoshi text-satoshi-heading-md tv:text-satoshi-heading-lg mb-[12px]">
                {feature.title}
              </h3>
              <div className="font-satoshi text-satoshi-body-md tv:text-satoshi-body-lg max-w-[438px]">
                {feature.content}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
