import classNames from "classnames";
import Section from "../../shared/section";
import { TitleGeneric } from "../../shared/title";
import TokenSectionHero from "../tokenSectionHero";
import PartnersShields from "./partnersShields";
import { useTranslation } from "react-i18next";
import FadeInUp from "../../shared/animations/fadeInUp";

export default function TokenSecurity() {
  const { t, ready } = useTranslation("token-page");

  if (!ready) return null;

  return (
    <Section className="mt-[188px] mb-[145px]">
      <FadeInUp>
        <div
          className={classNames(
            "text-black bg-yellow-0 shadow-subtle rounded-xl p-[24px] pb-[80px] relative",
            "mh:pb-[104px] tv:pt-[48px] th:pt-[56px] th:pb-[120px]"
          )}
        >
          <TokenSectionHero
            className={classNames(
              "!mt-0 mb-[24px]",
              "mh:text-center tv:max-w-[680px] tv:mx-auto tv:mb-[32px] th:mb-[64px]"
            )}
            title={t("security.title")}
            titleCx="tv:mb-[32px] th:mb-[48px]"
            subtitle={t("security.subtitle")}
            contentContainerCx="max-w-[640px] mh:mx-auto"
            subtitleCx="text-satoshi-heading-sm mh:text-satoshi-heading-md th:text-satoshi-heading-lg"
            content={t("security.content")}
            contentCx="text-satoshi-body-sm mh:text-satoshi-body-md th:text-satoshi-body-lg"
          />

          <TitleGeneric
            as="h3"
            className="text-center text-surface-3 font-satoshi text-satoshi-display-xs desktop:text-satoshi-display-sm"
          >
            {t("security.partners")}
          </TitleGeneric>

          <div className="px-[30px] left-1/2 bottom-0 w-full translate-x-[-50%] translate-y-[50%] absolute">
            <PartnersShields />
          </div>
        </div>
      </FadeInUp>
    </Section>
  );
}
