import image1 from "../../../static/images/team/image1.png";
import image2 from "../../../static/images/team/image2.png";
import image3 from "../../../static/images/team/image3.png";
import memoji_jordi_torras from "../../../static/images/members/memoji_jordi_torras.png";
import memoji_david_tomu from "../../../static/images/members/memoji_david_tomu.png";
import memoji_jose_puche from "../../../static/images/members/memoji_jose_puche.png";
import memoji_marc_bigas from "../../../static/images/members/memoji_marc_bigas.png";
import memoji_jordi_julia from "../../../static/images/members/memoji_jordi_julia.png";
import memoji_juan_antonio_heredia from "../../../static/images/members/memoji_juan_antonio_heredia.png";
import memoji_sergi_moral from "../../../static/images/members/memoji_sergi_moral.png";
import memoji_marina_vilageliu from "../../../static/images/members/memoji_marina_vilageliu.png";
import memoji_nico_salomone from "../../../static/images/members/memoji_nico_salomone.png";
import memoji_giulia_zilibotti from "../../../static/images/members/memoji_giulia_zilibotti.png";
import memoji_julio_lax from "../../../static/images/members/memoji_julio_lax.png";
import memoji_ariadna_gimenez from "../../../static/images/members/memoji_ariadna_gimenez.png";
import memoji_joaquim_salvado from "../../../static/images/members/memoji_joaquim_salvado.png";
import memoji_roberto_ulloa from "../../../static/images/members/memoji_roberto_ulloa.png";
import memoji_daniel_lopez_doriga from "../../../static/images/members/memoji_daniel_lopez_doriga.png";
import memoji_sara_sanchez from "../../../static/images/members/memoji_sara_sanchez.png";
import memoji_german_debonis from "../../../static/images/members/memoji_german_debonis.png";
import memoji_isidoro_arostegui from "../../../static/images/members/memoji_isidoro_arostegui.png";
import memoji_jorge_gomes from "../../../static/images/members/memoji_jorge_gomes.png";
import memoji_ellie_xifra from "../../../static/images/members/memoji_ellie_xifra.png";
import memoji_alex_granados from "../../../static/images/members/memoji_alex_granados.png";
import memoji_angela_garcia from "../../../static/images/members/memoji_angela_garcia.png";
import memoji_jordi_corominas from "../../../static/images/members/memoji_jordi_corominas.png";
import memoji_rodrigo_martinez from "../../../static/images/members/memoji_rodrigo_martinez.png";
import memoji_adriana_gomez from "../../../static/images/members/memoji_adriana_gomez.png";

export const images = [image1, image2, image3, image1, image2, image3];

export const teamMembers = [
  {
    name: "Jordi Torras",
    position: "CEO & Founder",
    emoji: "🎮",
    emojiTheme: "bg-surface-10",
    memoji: memoji_jordi_torras,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/jordi-torras-425361a" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "David Tomu",
    position: "CIO & Founder",
    emoji: "🛹",
    emojiTheme: "bg-surface-10",
    memoji: memoji_david_tomu,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/dgtomu" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Jose Puche",
    position: "Executive CPO",
    emoji: "🍙",
    emojiTheme: "bg-blue-0",
    memoji: memoji_jose_puche,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/josepuche" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Marc Bigas",
    position: "Executive VP",
    emoji: "⛷",
    emojiTheme: "bg-surface-10",
    memoji: memoji_marc_bigas,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/marcbigas" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },

  {
    name: "Jordi Julià",
    position: "Head of Marketing",
    emoji: "🪀",
    emojiTheme: "bg-yellow-0",
    memoji: memoji_jordi_julia,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/jordi-julià-5692a312" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Juan Antonio Heredia",
    position: "CTO & Project Manager",
    emoji: "🦁",
    emojiTheme: "bg-red-0",
    memoji: memoji_juan_antonio_heredia,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/juanantonioheredia" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Sergi Moral",
    position: "Communications Manager",
    emoji: "🎙️",
    emojiTheme: "bg-surface-10",
    memoji: memoji_sergi_moral,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/sergi-moral" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Marina Vilageliu",
    position: "HR Manager",
    emoji: "🫰",
    emojiTheme: "bg-blue-0",
    memoji: memoji_marina_vilageliu,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/marinavilageliu" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Nico Salomone",
    position: "Lead Product Designer",
    emoji: "🤗",
    emojiTheme: "bg-surface-10",
    memoji: memoji_nico_salomone,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/nssalomone/" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Giulia Zilibotti",
    position: "Product Designer",
    emoji: "🌏",
    emojiTheme: "bg-yellow-0",
    memoji: memoji_giulia_zilibotti,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/giulia-zilibotti-147g" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Julio Lax",
    position: "Rungie Studios Manager",
    emoji: "👾",
    emojiTheme: "bg-green-0",
    memoji: memoji_julio_lax,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/julio-lax-79407786" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Ariadna Gimenez",
    position: "Rungie Studios Designer",
    emoji: "🦋",
    emojiTheme: "bg-blue-0",
    memoji: memoji_ariadna_gimenez,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/ariadna-giménez-336293197" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Joaquim Salvadó",
    position: "Blockchain and Backend Developer",
    emoji: "🛠️",
    emojiTheme: "bg-yellow-0",
    memoji: memoji_joaquim_salvado,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/quim-salvado" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Roberto Ulloa",
    position: "Global System Architecture",
    emoji: "🐅",
    emojiTheme: "bg-surface-10",
    memoji: memoji_roberto_ulloa,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/roberulloa" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Daniel Lopez-Doriga",
    position: "Backend Architect Developer",
    emoji: "🎸",
    emojiTheme: "bg-surface-10",
    memoji: memoji_daniel_lopez_doriga,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/daniel-lopez-ds" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Sara Sánchez",
    position: "QA Engineer",
    emoji: "😻",
    emojiTheme: "bg-red-0",
    memoji: memoji_sara_sanchez,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/sara-sanchez-rodrigo" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Germán Debonis",
    position: "Frontend Engineer",
    emoji: "🤖",
    emojiTheme: "bg-green-0",
    memoji: memoji_german_debonis,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/german-schneck" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Isidoro Aróstegui",
    position: "Frontend Developer",
    emoji: "🧉",
    emojiTheme: "bg-blue-0",
    memoji: memoji_isidoro_arostegui,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/isidoro-ar" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Jorge Gomes",
    position: "Blockchain/Smart Contract Developer",
    emoji: "👽",
    emojiTheme: "bg-yellow-0",
    memoji: memoji_jorge_gomes,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/jgomesduran" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Ellie Xifra",
    position: "Social Media Manager",
    emoji: "🦖",
    emojiTheme: "bg-green-0",
    memoji: memoji_ellie_xifra,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/elliexifra" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Alex Granados",
    position: "Frameit Marketing Manager",
    emoji: "🚀",
    emojiTheme: "bg-red-0",
    memoji: memoji_alex_granados,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/thisisalexgranados" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Ángela García",
    position: "Account Manager",
    emoji: "💚",
    emojiTheme: "bg-green-0",
    memoji: memoji_angela_garcia,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/ángela-g-lerma-8138881a2" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Jordi Corominas",
    position: "Business Developer",
    emoji: "👓",
    emojiTheme: "bg-green-0",
    memoji: memoji_jordi_corominas,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/jordi-corominas" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Rodrigo Martínez",
    position: "Business Developer",
    emoji: "🏎️",
    emojiTheme: "bg-red-0",
    memoji: memoji_rodrigo_martinez,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/rodrigomartinezguiterrez" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
  {
    name: "Adriana Gomez",
    position: "Product Designer",
    emoji: "🏝️",
    emojiTheme: "bg-red-0",
    memoji: memoji_adriana_gomez,
    socialNetworks: [
      { network: "linkedin", url: "https://www.linkedin.com/in/adriana-gomez-/" },
      //{ network: "discord", url: "https://discord.com/" },
    ],
  },
];
