import Emoji from "../emoji";

export default function Panel({
  title,
  content = null,
  elements = [],
  modalButton = null,
}) {
  return (
    <section className="p-[24px] mh:p-[40px] desktop:p-[64px] bg-white rounded-2xl text-black">
      <h2 className="font-extrabold max-w-[480px] font-satoshi mb-[32px] desktop:mb-[56px] tv:max-w-[70%] desktop:max-w-[50%] th:max-w-[50%] text-satoshi-heading-sm mh:text-satoshi-heading-md desktop:text-satoshi-heading-lg">
        {title}
      </h2>

      {content && <div>{content}</div>}
      {elements.length > 0 && (
        <div className="flex flex-col gap-[24px] mh:gap-[24px] th:grid th:grid-cols-2 th:grid-rows-2 th:gap-[32px] th:gap-x-[24px] desktop:gap-[48px]">
          {elements.map((e, i) => (
            <Element key={i} {...e} />
          ))}
        </div>
      )}
      {modalButton && (
        <div className="mt-[32px] desktop:mt-[48px]">{modalButton}</div>
      )}
    </section>
  );
}

const Element = ({ emoji, emojiBgColor, text }) => (
  <div className="flex items-start gap-[16px] mh:items-center mh:gap-[24px]">
    <Emoji
      symbol={emoji}
      padding="p-[12px] mh:p-[16px] th:p-[20px] th:p-[10px]"
      className="mh:text-[32px] mh:rounded-xl th:text-[40px] th:rounded-2xl"
      bgColor={emojiBgColor}
    />
    <span className="text-satoshi-body-md desktop:text-satoshi-body-lg">
      {text}
    </span>
  </div>
);
