import { Trans, useTranslation } from "react-i18next";
import Section from "../../shared/section";
import UtilityCards from "./utilityCards";
import TokenSectionHero from "../tokenSectionHero";
import { TitleSpan } from "../../shared/title";
import FadeInUp from "../../shared/animations/fadeInUp";

export default function TokenUtility() {
  const { t, ready, i18n } = useTranslation("token-page");

  if (!ready) return null;

  const utilities = [
    {
      title: t("utility.items.0.title"),
      content: t("utility.items.0.content"),
      bgColor: "bg-green-0",
    },
    {
      title: t("utility.items.1.title"),
      content: t("utility.items.1.content"),
      bgColor: "bg-yellow-0",
    },
    {
      title: t("utility.items.2.title"),
      content: t("utility.items.2.content"),
      bgColor: "bg-red-0",
    },
    {
      title: t("utility.items.3.title"),
      content: t("utility.items.3.content"),
      bgColor: "bg-blue-0",
    },
  ];

  return (
    <Section className="text-black tv:max-desktop:px-[72px] tv:max-desktop:py-[24px] th:max-desktop:px-[80px]">
      <FadeInUp>
        <TokenSectionHero
          className="mh:text-center mb-[48px] tv:mb-[56px] th:mb-[64px] flex flex-col items-start mh:items-center"
          titleCx="mh:whitespace-nowrap tv:mb-[32px] th:mb-[48px]"
          subtitleCx="th:mb-[24px] desktop:text-satoshi-heading-lg"
          contentCx="desktop:text-satoshi-body-lg"
          contentContainerCx="th:max-w-[480px] desktop:max-w-[640px]"
          title={
            <Trans
              i18n={i18n}
              i18nKey="utility.title"
              ns="token-page"
              components={[<TitleSpan>valor</TitleSpan>]}
            />
          }
          subtitle={t("utility.subtitle")}
          content={t("utility.content")}
        />
      </FadeInUp>
      <div className="mb-[100px]">
        <FadeInUp>
          <UtilityCards utilities={utilities} />
        </FadeInUp>
      </div>
    </Section>
  );
}
