import { useState } from "react";
import newsletterVid from "../../static/videos/newsletter.webm";
import newsletterVidSafari from "../../static/videos/newsletter.mov";
import Button from "../shared/button";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Input from "../shared/input";
import { mailerliteSubscribe } from "../../api/mailerlite";

const isValidEmail = (email) => {
  return new RegExp( // eslint-disable-next-line
    /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/g
  ).test(email);
};

export default function Newsletter() {
  const [subscribed, setSubscribed] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { t, ready } = useTranslation("common");

  if (!ready) return null;

  const handleChange = (e) => {
    setEmail(e.target.value);
    if (error && isValidEmail(e.target.value)) {
      setError("");
    }
  };

  const handleSubmit = () => {
    const emailError = t("newsletter.email-error");

    if (isValidEmail(email)) {
      return mailerliteSubscribe(email)
        .then(() => {
          setSubscribed(true);
          setError("");
        })
        .catch((e) => {
          setSubscribed(false);
          console.log("🚀 > e:", e);
          setError(emailError);
        });
    }

    setError(emailError);
  };

  const redirectToLink = () => {
    window.location.href = "https://discord.gg/WzwZgdUJYy";
  };

  return (
    <section
      className={classNames(
        "bg-yellow-30-primary flex flex-col items-center p-[24px] pt-[0px] text-black rounded-2xl shadow-subtle",
        "tv:flex-row tv:gap-[8px] th:gap-[48px] th:justify-center"
      )}
    >
      <div className="flex items-center justify-center">
        <div className="w-[120px] tv:w-[230px] th:w-[242px] desktop:w-[324px]">
          <video width="100%" autoPlay muted playsInline>
            <source src={newsletterVidSafari} type="video/quicktime" />
            <source src={newsletterVid} type="video/webm" />
          </video>
        </div>
      </div>
      <div className="max-w-[500px] desktop:max-w-[620px]">
        <h3 className="font-satoshi text-satoshi-heading-xs desktop:text-satoshi-heading-md mb-[16px]">
          {t(subscribed ? "newsletter.subscribed" : "newsletter.subscribe")}
        </h3>

        <div
          className={classNames(
            "flex flex-col items-start gap-[10px] mh:gap-[8px] w-full mb-[10px]",
            !subscribed && "mh:items-center mh:flex-row"
          )}
        >
          {subscribed && <div>{t("newsletter.did-u-know")}</div>}
          {!subscribed && (
            <>
              <Input
                error={error}
                onChange={handleChange}
                placeholder={t("newsletter.placeholder")}
                onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
              />
              {error && <span>{error}</span>}
            </>
          )}
          <div className="w-full">
            <Button
              className="flex-shrink-0"
              onClick={!subscribed ? handleSubmit : redirectToLink}
            >
              {t(subscribed ? "newsletter.join" : "newsletter.submit")}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
