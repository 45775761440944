"use client";
import Chart from "chart.js/auto";
import { useRef, useEffect, useState } from "react";
import { chartDataES, chartDataEN } from "./constants";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export default function DistributionChart() {
  const [chart, setChart] = useState(null);
  const [activeLabel, setActiveLabel] = useState(null);
  const canvas = useRef();

  const { i18n } = useTranslation();

  const chartData = i18n.language === "es" ? chartDataES : chartDataEN;

  useEffect(() => {
    const ctx = canvas.current;

    let chartStatus = Chart.getChart(0);
    if (chartStatus !== undefined) {
      chartStatus.destroy();
    }

    const chart = new Chart(ctx, chartData);

    setChart(chart);

    return () => {
      chart.destroy();
    };
  }, [chartData]);

  const handleLabelClick = (label) => {
    const labelIndex = chartData.data.labels.indexOf(label);

    const activeElements = chart.getActiveElements();
    const differentLabelClick = activeElements.find(
      (e) => e.index === labelIndex
    );

    if (activeElements.length > 0 && !Boolean(differentLabelClick)) {
      setActiveLabel(null);
      chart.setActiveElements([]);
      chart.update();
      return;
    }

    triggerHover(labelIndex);
  };

  const triggerHover = (labelIdx) => {
    const otherDatasets = chartData.data.datasets[0].data
      .map((e, i) => (i !== labelIdx ? { datasetIndex: 0, index: i } : null))
      .filter(Boolean);
    chart.setActiveElements(otherDatasets);
    chart.update();
    setActiveLabel(labelIdx);
  };

  return (
    <div className="flex flex-col tv:flex-row tv:items-center tv:gap-[60px] th:gap-[72px] desktop:gap-[184px]">
      <div>
        <div className="mb-[48px]">
          <LabelGroup
            title="Comunidad"
            labels={chartData.data.labels.slice(0, 5)}
            labelColors={chartData.data.datasets[0].backgroundColor.slice(0, 5)}
            dataPercents={chartData.data.datasets[0].data.slice(0, 5)}
            onLabelClick={handleLabelClick}
            groupStartIndex={0}
            activeLabel={activeLabel}
          />
          <LabelGroup
            title="Team"
            labels={chartData.data.labels.slice(5)}
            labelColors={chartData.data.datasets[0].backgroundColor.slice(5)}
            dataPercents={chartData.data.datasets[0].data.slice(5)}
            onLabelClick={handleLabelClick}
            groupStartIndex={5}
            activeLabel={activeLabel}
          />
        </div>
      </div>
      <div className="flex items-center justify-center flex-grow">
        <div className="w-[240px] th:w-[320px] desktop:w-[384px]">
          <canvas
            className="mx-auto w-[240px] th:w-[320px] desktop:w-[384px]"
            ref={canvas}
          ></canvas>
        </div>
      </div>
      <div
        className={classNames(
          "mt-[16px] w-full mx-auto flex-shrink",
          "mh:max-w-[346px] mh:mt-[32px] tv:max-w-[240px] desktop:max-w-[280px]"
        )}
      >
        {activeLabel !== null && (
          <div className="text-left mh:text-center">
            <div className="flex items-end justify-between mh:flex-col-reverse mh:items-center mh:gap-[24px]">
              <div className="text-satoshi-heading-md mh:text-satoshi-heading-lg">
                {chartData.data.labels[activeLabel]}
              </div>
              <div
                className={classNames(
                  "text-[48px] leading-[48px] font-bold",
                  "mh:text-[64px] mh:leading-[64px]"
                )}
              >
                {chartData.data.datasets[0].data[activeLabel]}%
              </div>
            </div>
            <hr className="my-[16px] mh:my-[24px]" />
            <div>Some text here</div>
          </div>
        )}
      </div>
    </div>
  );
}

const LabelGroup = ({
  title,
  labels,
  labelColors,
  dataPercents,
  onLabelClick,
  activeLabel,
  groupStartIndex,
}) => (
  <div className="mb-[24px]">
    <div className="mb-[24px]">
      <span className="text-satoshi-heading-sm th:text-satoshi-heading-md">
        {title}
      </span>
    </div>
    <div className="flex flex-wrap gap-[8px] tv:flex-col">
      {labels.map((label, i) => {
        const labelColor = labelColors[i];
        const dataPercent = dataPercents[i];
        return (
          <ChartLabel
            key={i}
            label={`${label}: ${dataPercent}%`}
            color={labelColor}
            onLabelClick={() => onLabelClick(label)}
            isActive={i + groupStartIndex === activeLabel}
          />
        );
      })}
    </div>
  </div>
);

const ChartLabel = ({ label, color, isActive, onLabelClick }) => (
  <div
    className={classNames(
      "cursor-pointer inline-flex w-[max-content] items-center gap-[8px] border-2 border-white rounded-lg p-[8px] font-bold text-satoshi-ui-md",
      "th:text-satoshi-ui-lg",
      isActive && "bg-white text-black"
    )}
    onClick={() => onLabelClick(label)}
  >
    <div
      className="w-[16px] h-[16px] th:w-[20px] th:h-[20px] rounded-md"
      style={{ backgroundColor: color }}
    />
    <span className="text-satoshi-ui-md th:text-satoshi-ui-lg">{label}</span>
  </div>
);
