import classNames from "classnames";
import Emoji from "../../shared/emoji";

export default function EcosystemCards({ cards = [] }) {
  return (
    <div
      className={classNames(
        "grid grid-cols-2 grid-rows-3 gap-[16px] my-[48px]",
        "th:grid-rows-2 th:max-desktop:grid-cols-[auto_1fr] desktop:grid-rows-1 desktop:grid-cols-3"
      )}
    >
      {cards.map((card, k) => (
        <div
          key={k}
          className={classNames(
            "flex flex-col col-span-2 gap-[16px] bg-white text-black p-[16px] rounded-lg",
            "mh:p-[24px] tv:p-[32px] desktop:col-span-1 th:max-desktop:col-span-1 desktop:min-h-[456px] desktop:justify-between",
            k === 0 &&
              "th:max-desktop:row-span-2 th:max-desktop:justify-between th:max-desktop:w-[385px]"
          )}
        >
          <div className="text-satoshi-ui-md tv:text-satoshi-ui-lg uppercase">
            {card.category}
          </div>
          <div>
            <div className="text-satoshi-heading-md tv:text-satoshi-heading-lg">
              {card.title}
            </div>
            <div className="mt-[16px] text-satoshi-body-md tv:text-satoshi-body-lg">
              {card.content}
            </div>
          </div>
          <div>
            <div className="text-[24px] tv:text-[32px] desktop:text-[38px] inline-block">
              <Emoji symbol={card.emoji} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
