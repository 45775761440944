import { useTranslation } from "react-i18next";
import Section from "../../shared/section";
import TokenSectionHero from "../tokenSectionHero";
import FaqsPanel from "./faqsPanel";
import FadeInUp from "../../shared/animations/fadeInUp";

export default function TokenFaqs() {
  const { t, ready } = useTranslation("token-page");

  if (!ready) return null;

  return (
    <Section className="mb-[300px]">
      <FadeInUp>
        <TokenSectionHero title={t("faqs.title")} />
      </FadeInUp>
      <FadeInUp>
        <FaqsPanel />
      </FadeInUp>
    </Section>
  );
}
