import classNames from "classnames";
import { Title } from "../../shared/title";

export default function TokenSectionHero({
  title,
  titleCx,
  subtitle,
  subtitleCx,
  content,
  contentCx,
  contentContainerCx,
  className,
  cta = null,
  ctaCx,
}) {
  return (
    <div className={classNames("mb-[100px] mt-[80px]", className)}>
      {title && (
        <Title className={classNames("mb-[24px]", titleCx)}>{title}</Title>
      )}
      <div
        className={classNames(
          "max-w-[680px] th:max-width-[480px] mx-auto",
          contentContainerCx
        )}
      >
        {subtitle && (
          <h2
            className={classNames(
              "mb-[16px] font-satoshi text-satoshi-heading-sm",
              subtitleCx
            )}
          >
            {subtitle}
          </h2>
        )}
        {content && (
          <div
            className={classNames(
              "font-satoshi text-satoshi-body-md",
              contentCx
            )}
          >
            {content}
          </div>
        )}
        {cta && <div className="mt-[24px]">{cta}</div>}
      </div>
    </div>
  );
}
