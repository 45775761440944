import mitoImage from "../../../static/images/projects/mito.png";
import frameitImage from "../../../static/images/projects/frameit.png";
import rungieImage from "../../../static/images/projects/rungie.png";

export const PROJECTS = [
  {
    image: mitoImage,
    host: "mito.io",
    hostUrl: "https://mito.io/",
    hostTheme: "light",
    sliderTheme: "red",
    controlsTheme: "bg-red-max",
    buttonTheme: "#972400",
    ctaTheme: "red",
    ctaTextColor: "text-red-max",
    hasCta: true,
    ctaSlide: 0,
    numSlides: 3,
    socialUrls: [
      { network: "instagram", url: "https://www.instagram.com/mitogallery/" },
      { network: "twitter", url: "https://twitter.com/mitogallery" },
      { network: "medium", url: "https://mitogallery.medium.com/" },
    ],
  },
  {
    image: frameitImage,
    host: "frameit.io",
    hostUrl: "https://frameit.io/",
    hostTheme: "light",
    sliderTheme: "green",
    controlsTheme: "bg-green-max",
    buttonTheme: "#3E6329",
    numSlides: 4,
    direction: "rtl",
    socialUrls: [
      { network: "instagram", url: "https://www.instagram.com/weareframeit/" },
      { network: "twitter", url: "https://twitter.com/weareFrameIt" },
      { network: "tiktok", url: "https://www.tiktok.com/@weareframeit" },
    ],
  },
  {
    image: rungieImage,
    host: "token.rungie.io",
    hostUrl: "/token",
    hostTheme: "dark",
    sliderTheme: "yellow",
    controlsTheme: "bg-orange-max",
    buttonTheme: "#EB8047",
    ctaTheme: "orange",
    ctaTextColor: "text-surface-0",
    hasCta: true,
    ctaSlide: 3,
    numSlides: 3,
    socialUrls: [
      { network: "twitter", url: "https://twitter.com/RungieLabs" },
      { network: "discord", url: "https://discord.gg/WzwZgdUJYy" },
      { network: "tiktok", url: "https://www.tiktok.com/@rungielabs" },
    ],
  },
];
