import classNames from "classnames";

export default function Input({ className, error, ...props }) {
  return (
<input
  className={classNames(
    "border-2 border-[1px] px-[12px] py-[12px] pt-[10px] text-satoshi-ui-md font-bold w-[100%] rounded-lg",
    "focus-visible:ring-2 focus-visible:ring-surface-0-primary focus-visible:border-surface-0-primary focus:outline-none",
    "focus-visible:hover:bg-surface-10 ease-out duration-150 transition-all",
    error && "bg-red-0 text-red-80 hover:bg-red-0 border-[1px] border-red-80",
    className
  )}
  style={{
    paddingTop: "12px",
    paddingBottom: "14px",
    lineHeight: "18px"
  }}
  {...props}
/>
  );
}
