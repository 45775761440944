import gsr from "../../../static/images/security/gsr.png";
import mme from "../../../static/images/security/mme.png";
import solidify from "../../../static/images/security/solidify.png";

export default function PartnersShields() {
  return (
    <div className="flex gap-[16px] w-full justify-center">
      <img className="w-[82px] mh:w-[118px] th:w-[142px]" src={mme} alt="MME" />
      <img className="w-[82px] mh:w-[118px] th:w-[142px]" src={gsr} alt="GSR" />
      <img
        className="w-[82px] mh:w-[118px] th:w-[142px]"
        src={solidify}
        alt="Solidify"
      />
    </div>
  );
}
