const datasets = [
  {
    label: "%",
    data: [45, 15, 5, 3, 2, 15, 15],
    backgroundColor: [
      "#F9B854FF",
      "#9ECA89FF",
      "#F7D75EFF",
      "#F6C9C6FF",
      "#CF6D58FF",
      "#EB8047FF",
      "#99CFE4FF",
    ],
    hoverBackgroundColor: [
      "#F9B85422",
      "#9ECA8922",
      "#F7D75E22",
      "#F6C9C622",
      "#CF6D5822",
      "#EB804722",
      "#99CFE422",
    ],
    borderColor: [
      "#113136",
      "#113136",
      "#113136",
      "#113136",
      "#113136",
      "#113136",
    ],
    borderWidth: 3,
    borderRadius: 8,
    hoverOffset: 4,
    rotation: 160,
  },
];

const sharedOptions = {
  type: "doughnut",
  options: {
    responsive: true,
    events: [],
    plugins: { legend: { display: false } },
  },
};

export const chartDataES = {
  ...sharedOptions,
  data: {
    datasets: datasets,
    labels: [
      "Tesorería",
      "Ecosistema",
      "Airdrop",
      "Partners",
      "Advisors",
      "Equipo",
      "Ventas",
    ],
  },
};

export const chartDataEN = {
  ...sharedOptions,
  data: {
    datasets: datasets,
    labels: [
      "Treasury",
      "Ecosystem",
      "Airdrop",
      "Partners",
      "Advisors",
      "Team",
      "Sales",
    ],
  },
};
