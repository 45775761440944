import { Trans, useTranslation } from "react-i18next";
import Section from "../../shared/section";
import { Title } from "../../shared/title";
import EcosystemCards from "./ecosystemCards";
import { TitleSpan } from "../../shared/title";
import FadeInUp from "../../shared/animations/fadeInUp";

const ecosystem = [
  {
    category: "ecosystem.items.0.category",
    title: "ecosystem.items.0.title",
    content: "ecosystem.items.0.content",
    emoji: "🎯",
  },
  {
    category: "ecosystem.items.1.category",
    title: "ecosystem.items.1.title",
    content: "ecosystem.items.1.content",
    emoji: "💬",
  },
  {
    category: "ecosystem.items.2.category",
    title: "ecosystem.items.2.title",
    content: "ecosystem.items.2.content",
    emoji: "🏅",
  },
];

export default function TokenEcosystem() {
  const { t, ready, i18n } = useTranslation("token-page");
  if (!ready) return null;

  const ecosystemI18n = ecosystem.map((ec) => ({
    ...ec,
    category: t(ec.category),
    title: t(ec.title),
    content: t(ec.content),
  }));

  return (
    <Section background="bg-surface-0-primary tv:max-th:px-[72px] tv:max-th:py-[24px]">
      <FadeInUp>
        <Title className="text-white mt-[48px] tv:max-th:max-w-[592px]">
          <Trans
            i18n={i18n}
            i18nKey="ecosystem.title"
            ns="token-page"
            components={[<TitleSpan>ecosistema</TitleSpan>]}
          />
        </Title>
      </FadeInUp>
      <FadeInUp>
        <EcosystemCards cards={ecosystemI18n} />
      </FadeInUp>
    </Section>
  );
}
