import { useState } from "react";
import Emoji from "../../shared/emoji";
import Section from "../../shared/section";
import { TitleSpan } from "../../shared/title";
import TokenSectionHero from "../tokenSectionHero";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";
import Icon from "../../shared/icon";
import FadeInUp from "../../shared/animations/fadeInUp";

const values = [
  {
    bgColor: "bg-red-0",
    emoji: "👛",
    title: "values.items.0.title",
    description: "values.items.0.description",
  },
  {
    bgColor: "bg-yellow-0",
    emoji: "⚡️",
    title: "values.items.1.title",
    description: "values.items.1.description",
  },
  {
    bgColor: "bg-blue-0",
    emoji: "🗳️",
    title: "values.items.2.title",
    description: "values.items.2.description",
  },
  {
    bgColor: "bg-green-0",
    emoji: "🔑",
    title: "values.items.3.title",
    description: "values.items.3.description",
  },
];

export default function TokenValues() {
  const { t, ready, i18n } = useTranslation("token-page");
  const [mode, setMode] = useState("grid");
  const [initialActiveCard, setIndex] = useState(null);

  if (!ready) return null;

  const toggleMode = (valueIndex = null) => {
    setMode((prev) => (prev === "grid" ? "card" : "grid"));
    if (typeof valueIndex == "number") setIndex(valueIndex);
  };

  const valuesWithI18n = values.map((v) => ({
    ...v,
    title: t(v.title),
    description: t(v.description),
  }));

  return (
    <Section className="tv:max-th:px-[72px] tv:max-th:py-[24px]">
      <FadeInUp>
        <div
          className={classNames(
            "bg-white text-black flex flex-col rounded-xl p-[24px]",
            "th:grid th:grid-cols-2 th:p-[40px] th:gap-[40px] desktop:p-[64px]"
          )}
        >
          <TokenSectionHero
            className="flex-grow mt-0 mb-[24px] mh:max-th:mb-[32px]"
            contentContainerCx="mx-0"
            title={
              <Trans
                i18n={i18n}
                ns="token-page"
                i18nKey="values.title"
                components={[<TitleSpan>clave</TitleSpan>]}
              />
            }
            subtitle={t("values.subtitle")}
            content={t("values.description")}
          />
          {mode === "grid" && (
            <ValuesGrid values={valuesWithI18n} onClick={toggleMode} />
          )}
          {mode === "card" && (
            <ValuesCards
              values={valuesWithI18n}
              initialActiveCard={initialActiveCard}
              onClose={toggleMode}
            />
          )}
        </div>
      </FadeInUp>
    </Section>
  );
}

const ValuesGrid = ({ values = [], onClick }) => {
  return (
    <div
      className={classNames(
        "grid grid-cols-2 flex-wrap gap-[16px]",
        "mh:max-th:min-h-[488px] tv:max-th:min-h-[532px] th:min-h-[392px] desktop:min-h-[528px]"
      )}
    >
      {values.map((value, i) => (
        <div
          key={i}
          onClick={() => onClick(i)}
          className={classNames(
            value.bgColor,
            "p-[16px] min-h-[180px] justify-center rounded-xl flex flex-col items-center gap-[24px] cursor-pointer"
          )}
        >
          <Emoji className="text-[32px] mh:text-[48px]" symbol={value.emoji} />
          <span className="text-center text-satoshi-heading-xs desktop:text-satoshi-heading-md th:text-satoshi-heading-sm tv:text-satoshi-heading-md mh:text-satoshi-heading-xs">
            {value.title}
          </span>
        </div>
      ))}
    </div>
  );
};

const ValuesCards = ({ initialActiveCard = 0, values = [], onClose }) => {
  const [activeCard, setActiveCard] = useState(initialActiveCard);

  const handleSlideAction = (action) => {
    if (action === "prev") {
      setActiveCard((prev) => (prev === 0 ? values.length - 1 : prev - 1));
    }
    if (action === "next") {
      setActiveCard((prev) => (prev === values.length - 1 ? 0 : prev + 1));
    }
  };

  return (
    <div className="grid flex-wrap gap-[16px]">
      {values.map((value, i) => (
        <div
          key={i}
          className={classNames(
            activeCard !== null && activeCard !== i && "hidden",
            value.bgColor,
            "relative w-full p-[24px] min-h-[376px] rounded-lg flex flex-col items-center justify-between gap-[16px]",
            "mh:p-[40px] mh:max-th:min-h-[488px] tv:max-th:min-h-[532px] th:min-h-[392px] desktop:min-h-[528px]"
          )}
        >
          <div className="cursor-pointer absolute top-[24px] right-[24px]">
            <Icon name="close" onClick={onClose} />
          </div>
          <div
            className={classNames(
              "flex p-[8px] flex-col gap-[12px] items-start justify-start mt-[20px]",
              "mh:flex-grow mh:text-center mh:justify-center mh:gap-[24px]"
            )}
          >
            <div className="mh:w-full mh:text-center">
              <div
                className={classNames(
                  "mb-[3px] text-satoshi-display-xs",
                  "mh:text-satoshi-display-sm tv:text-satoshi-display-md th:text-satoshi-display-sm"
                )}
              >
                <Emoji symbol={value.emoji} />
              </div>
            </div>
            <span className="w-full text-satoshi-heading-md mh:text-satoshi-heading-lg">
              {value.title}
            </span>
            <span className="w-full text-satoshi-body-sm mh:text-satoshi-body-md tv:text-satoshi-body-lg">
              {value.description}
            </span>
          </div>
          <div className="flex justify-between w-full">
            <div
              className="cursor-pointer"
              onClick={() => handleSlideAction("prev")}
            >
              <Icon name="chevron_down" className="rotate-90" />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => handleSlideAction("next")}
            >
              <Icon name="chevron_down" className="-rotate-90" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
