import { useTranslation } from "react-i18next";
import Icon from "../icon";
import SocialNetworks from "../socialNetworks";
import classNames from "classnames";

export default function Footer() {
  const { t, ready } = useTranslation("footer");

  if (!ready) return null;

  return (
    <footer className="bg-white py-[35px] px-[24px] desktop:px-[64px] desktop:py-[24px]">
      <div
        className={classNames(
          "w-full flex items-center justify-between"
        )}
      >
        <span className="text-satoshi-ui-lg text-surface-1 font-bold max-w-[136px] tv:max-w-none">
          <a href="mailto:info@rungie.io" rel="noopener noreferrer">
            info@rungie.io
          </a>
        </span>

        <span className="flex col-span-2 tv:col-span-1 tv:justify-end">
          <SocialNetworks
            size="32px"
            networks={[
              { network: "twitter", url: "https://twitter.com/RungieLabs" },
              { network: "medium", url: "https://mitogallery.medium.com/" },
              { network: "discord", url: "https://discord.gg/WzwZgdUJYy" },
              { network: "tiktok", url: "https://www.tiktok.com/@rungielabs" },
            ]}
          />
        </span>
      </div>
    </footer>
  );
}
