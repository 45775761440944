import { faqs } from "./constants";
import { useState } from "react";
import classNames from "classnames";
import Emoji from "../../shared/emoji";
import Icon from "../../shared/icon";
import { useTranslation } from "react-i18next";

const colors = ["bg-yellow-0", "bg-red-0", "bg-surface-10", "bg-green-0"];

export default function FaqsPanel() {
  const [activeFaq, setActiveFaq] = useState(-1);

  const { t, ready } = useTranslation("token-page");

  if (!ready) return null;

  const toggleActiveFaq = (index) => {
    setActiveFaq(index === activeFaq ? -1 : index);
  };

  return (
    <div className="text-black bg-white py-[32px] px-[24px] mh:p-[32px] th:p-[64px] rounded-xl">
      <h2 className="font-satoshi text-satoshi-heading-md th:text-satoshi-heading-lg mb-[48px]">
        {t("faqs.panel.title")}
      </h2>
      {faqs.map((faq, i) => {
        const bgColor = colors[i % 4];
        const isActiveFaq = i === activeFaq;
        return (
          <div
            key={i}
            onClick={() => toggleActiveFaq(i)}
            className={classNames(
              "pb-[24px] mb-[24px] cursor-pointer",
              isActiveFaq ? "max-h-[1000px]" : "max-h-[120px]"
            )}
            style={{
              borderBottom: "1px solid rgba(123, 163, 170, 0.4)",
              transition: "max-height ease 0.4s",
            }}
          >
            <div className="flex gap-[24px] items-center">
              <div className="hidden mh:inline-block">
                <div>
                  <Emoji
                    className="w-[64px] h-[64px] text-[28px]"
                    padding="p-[16px]"
                    bgColor={bgColor}
                    symbol={faq.emoji}
                  />
                </div>
              </div>
              <div className="flex-grow text-satoshi-heading-xs mh:text-satoshi-heading-sm th:text-satoshi-heading-md">
                {t(faq.title)}
              </div>
              <div
                className={classNames(
                  "flex-shrink-0",
                  isActiveFaq && "-rotate-180"
                )}
                style={{ transition: "transform ease 0.3s" }}
              >
                <Icon
                  name="chevron_down"
                  className={isActiveFaq ? "mr-auto" : "ml-auto"}
                />
              </div>
            </div>
            <div
              className={classNames(
                i !== activeFaq && "hidden",
                "fadeIn mt-[24px] font-satoshi text-satoshi-body-md tv:text-satoshi-body-lg"
              )}
            >
              {t(faq.content)}
            </div>
          </div>
        );
      })}
    </div>
  );
}
