import { useEffect, useRef, useState } from "react";
import { images } from "./constants";
import Icon from "../../shared/icon";

export default function TeamSlider() {
  const containerRef = useRef(null);
  const carouselRef = useRef(null);

  const [state, setState] = useState({
    scroller: null,
    itemWidth: 0,
    isPrevHidden: true,
    isNextHidden: false,
  });

  const handleSliderAction = (action) => {
    state.scroller.scrollBy({
      left: action === "next" ? state.itemWidth : -state.itemWidth,
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const scroller = containerRef.current;
    const itemWidth = containerRef.current.firstElementChild?.clientWidth;

    setState({ ...state, scroller, itemWidth });

    return () => {};
    // eslint-disable-next-line
  }, []);

  const smallButtonClasses = `flex items-center justify-center w-[40px] h-[40px] bg-surface-2 flex-shrink-0 rounded-full`;

  return (
    <div ref={carouselRef} className="relative">
      <div
        ref={containerRef}
        className="flex flex-row gap-[24px] overflow-y-auto snap-mandatory snap-x"
      >
        {images.map((image, i) => (
          <div
            key={`${image}-${i}`}
            className="flex-shrink-0 w-[300px] h-[200px] tv:w-[400px] tv:h-[300px]"
            style={{
              scrollSnapAlign: "start",
              backgroundImage: `url(${image})`,
              backgroundSize: "115%",
              backgroundPosition: "center",
            }}
          />
        ))}
      </div>
      <div className="hidden absolute right-0 bottom-0 translate-y-1/2 gap-[16px] tv:flex">
        <button
          className={smallButtonClasses}
          onClick={() => handleSliderAction("prev")}
        >
          <Icon name="chevron_down" className="rotate-90" size="24px" />
        </button>
        <button
          className={smallButtonClasses}
          // disabled={activeSlide === slides.length - 1}
          onClick={() => handleSliderAction("next")}
        >
          <Icon name="chevron_down" className="-rotate-90" size="24px" />
        </button>
      </div>
    </div>
  );
}
