import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next/TransWithoutContext";
import Newsletter from "../../../components/newsletter";
import Section from "../../../components/shared/section";
import CoffeLoversModal from "./coffeLoversModal";
import Emoji from "../../../components/shared/emoji";
import { Title } from "../../shared/title";
import PartnersList from "./partnersList";
import FadeInUp from "../../shared/animations/fadeInUp";

export default function Partners({ withCoffeLovers = false }) {
  const { t, ready, i18n } = useTranslation("common");
  const [showModal, setShowModal] = useState(false);

  if (!ready) return null;

  const toggleModal = () => setShowModal((show) => !show);

  return (
    <>
      <Section background="bg-surface-0-primary" className="mt-[125px]">
        <div className="-translate-y-[50%]">
          <Newsletter />
        </div>
        <FadeInUp>
          <Title className="text-white text-center" as="h2">
            {t("partners")}
          </Title>
        </FadeInUp>

        <div className="mt-[80px] mb-[96px]">
          <FadeInUp>
            <PartnersList />
          </FadeInUp>
        </div>
      </Section>
      {withCoffeLovers && (
        <Section
          onClick={toggleModal}
          background="bg-[#EEE0D6]"
          className="relative text-center cursor-pointer"
          containerPaddings="py-[32px] mh:pt-[24px] mh:pb-[32px]"
        >
          <div className="absolute top-0 left-1/2 translate-x-[-50%] translate-y-[-65%] text-[80px]">
            <Emoji symbol="☕️" />
          </div>
          <div className="text-satoshi-display-xs text-center w-[247px] mh:w-auto mx-auto">
            <Trans
              i18nKey="coffe-lovers.title"
              ns="common"
              i18n={i18n}
              components={[
                <span key="0" className="font-kaisei text-kaisei-display-xs">
                  coffee lovers
                </span>,
              ]}
            />
          </div>
        </Section>
      )}
      {showModal && <CoffeLoversModal onClose={toggleModal} />}
    </>
  );
}
