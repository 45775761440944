import classNames from "classnames";
import Emoji from "../../shared/emoji";

export default function UtilityCards({ utilities = [] }) {
  return (
    <div className="grid grid-cols-1 mh:grid-cols-2 desktop:grid-cols-4 desktop:grid-rows-1 gap-[16px] tv:gap-[24px]">
      {utilities.map((utility, i) => (
        <div
          key={i}
          className="bg-white cursor-pointer text-black rounded-xl shadow-subtle overflow-hidden hover:scale-105"
          style={{ transition: "transform ease 0.3s" }}
        >
          <div
            className={classNames(
              "text-satoshi-heading-sm px-[24px] py-[16px]",
              "tv:text-satoshi-heading-md",
              utility.bgColor
            )}
          >
            {utility.title}
          </div>
          <div className="flex px-[24px] py-[16px] pb-[20px] gap-[10px]">
            <div>
              <Emoji symbol="👉" />
            </div>
            <div className="text-satoshi-body-sm tv:text-satoshi-body-md">
              {utility.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
