export const faqs = [
  {
    title: "faqs.panel.items.0.title",
    content: "faqs.panel.items.0.content",
    emoji: "😎",
  },
  {
    title: "faqs.panel.items.1.title",
    content: "faqs.panel.items.1.content",
    emoji: "🛍",
  },
  {
    title: "faqs.panel.items.2.title",
    content: "faqs.panel.items.2.content",
    emoji: "🏘",
  },
  {
    title: "faqs.panel.items.3.title",
    content: "faqs.panel.items.3.content",
    emoji: "💰",
  },
];
