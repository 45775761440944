import Partners from "../components/home/partners";
import tokenBg from "../static/images/bg_token.jpg";
import Navbar from "../components/navbar";
import Footer from "../components/shared/footer";
import TokenHero from "../components/token/hero";
import TokenEcosystem from "../components/token/ecosystem";
import TokenUtility from "../components/token/utility";
import TokenRoadmap from "../components/token/roadmap";
import TokenDistribution from "../components/token/distribution";
import TokenSecurity from "../components/token/security";
import TokenValues from "../components/token/values";
import TokenFaqs from "../components/token/faqs";
import FixedBackground from "../components/shared/fixedBackground";
import { useEffect, useState } from "react";

export default function TokenPage() {
  const [scrolledTop, setScrolledTop] = useState(false);
  useEffect(() => {
    if (!scrolledTop) {
      window.scrollTo(0, 0);
      setScrolledTop(true);
    }

    // eslint-disable-next-line
  }, []);
  return (
    <>
      <main className="relative overflow-auto">
        <FixedBackground image={tokenBg} />
        <Navbar bgImage={tokenBg} />
        <TokenHero />
        <TokenEcosystem />
        <TokenUtility />
        <TokenValues />
        {/* <TokenRoadmap /> */}
        <TokenDistribution />
        <TokenSecurity />
        <TokenFaqs />
        <Partners />
      </main>
      <Footer />
    </>
  );
}
